import React, { FC } from 'react';

import {
  useUnmountableCustomModalContext,
  UnmountableModalWrapper,
} from '../PlusDetail/UnmountableCustomModal';

import styles from './FraudPayments.module.scss';

interface FlagPaymentFraudButtonProps {
  text: string;
  className: string;
  disabled: boolean;
  children: React.ReactNode;
}

const FlagPaymentFraudButton: FC<FlagPaymentFraudButtonProps> = ({
  text,
  className,
  disabled,
  children,
}) => {
  const { onOpen } = useUnmountableCustomModalContext();

  return (
    <>
      <button className={className} onClick={onOpen} disabled={disabled}>
        {text}
      </button>
      <UnmountableModalWrapper className={styles['fraud-modal']}>
        {children}
      </UnmountableModalWrapper>
    </>
  );
};

export default FlagPaymentFraudButton;
