import gql from 'graphql-tag';

export interface PaymentActiveFraudFlag {
  paymentId: string;
  excludeFromBill: boolean;
  activeFraudFlagIds: string[];
}

export const getPaymentActiveFraudFlags = gql`
  query consumerPaymentsActiveFraudFlags($consumerId: ID!) {
    consumerPaymentsActiveFraudFlags(consumerId: $consumerId) {
      paymentsFraudFlags {
        paymentId
        excludeFromBill
        activeFraudFlagIds
      }
    }
  }
`;

export interface PaymentFraudFlaggingReason {
  id: string;
  description: string;
}

export const getPaymentFraudFlaggingReasons = gql`
  query paymentFraudFlaggingReasons {
    paymentFraudFlaggingReasons {
      flaggingReasons {
        id
        description
      }
    }
  }
`;

export const flagFraudPayments = gql`
  mutation flagPaymentsForFraud(
    $consumerId: ID!
    $flaggedFor: String!
    $excludeFromBill: Boolean!
    $paymentsToFlag: [PaymentToFlag!]!
  ) {
    flagPaymentsForFraud(
      consumerId: $consumerId
      flaggedFor: $flaggedFor
      excludeFromBill: $excludeFromBill
      paymentsToFlag: $paymentsToFlag
    ) {
      createdPaymentFraudFlags {
        fraudFlagId
        paymentId
      }
    }
  }
`;

export interface PaymentFraudUnflaggingReason {
  id: string;
  description: string;
}

export const getPaymentFraudUnflaggingReasons = gql`
  query paymentFraudUnflaggingReasons {
    paymentFraudUnflaggingReasons {
      unflaggingReasons {
        id
        description
      }
    }
  }
`;

export const unflagFraudPayment = gql`
  mutation unflagPaymentFraudFlags(
    $unflaggedFor: String!
    $fraudFlagId: [ID!]!
  ) {
    unflagPaymentFraudFlags(
      unflaggedFor: $unflaggedFor
      paymentFraudFlagIds: $fraudFlagId
    )
  }
`;
