import React, { useState, useEffect, FC } from 'react';
import { useParams } from '@reach/router';
import { useQuery } from '@apollo/react-hooks';

import Pagination from '../../Pagination/Pagination';

import {
  getPaymentFraudFlagsHistory,
  PaymentFraudFlagEvent,
} from './gql-query';
import { pagerize } from '../../../utils';
import { NUM_ITEMS_PER_PAGE } from '../../../constants';
import FraudFlagLogItem from './FraudFlagLogItem';
import FraudFlagDetail from './FraudFlagDetail';
import { PaymentFraudFlag } from '../gql-query';
import { PermissionChecker } from '../../PermissionChecker/PermissionChecker';
import { FRAUD_PERMISSION } from '../../../utils/hooks/use-fraud-permission';

import styles from './FraudFlagLogs.module.scss';
import { NoPermissionViewLog } from './NoPermissionViewLog';

const useFraudFlagHistory = () => {
  const { paymentId } = useParams();

  const { data, loading, error } = useQuery(getPaymentFraudFlagsHistory, {
    variables: { paymentId },
    fetchPolicy: 'cache-and-network',
  });

  const fraudFlagEvents: PaymentFraudFlagEvent[] =
    data?.paymentFraudFlagsHistory?.fraudFlagEvents || [];

  return { fraudFlagEvents, loading, error };
};

interface FraudFlagLogsProps {
  activeFraudFlags: PaymentFraudFlag[];
}

const FraudFlagLogs: FC<FraudFlagLogsProps> = ({ activeFraudFlags }) => {
  const { fraudFlagEvents = [] } = useFraudFlagHistory();

  const [page, setPage] = useState(0);

  const pageCount = fraudFlagEvents
    ? Math.ceil(fraudFlagEvents.length / NUM_ITEMS_PER_PAGE)
    : 0;

  const pagedFraudFlagEvents: PaymentFraudFlagEvent[] = pagerize({
    entries: fraudFlagEvents,
    page,
    perPage: NUM_ITEMS_PER_PAGE,
  });

  useEffect(() => {
    // page starts from 0
    // pageCount starts from 1
    if (page != 0 && page > pageCount - 1) {
      setPage(pageCount - 1);
    }
  }, [fraudFlagEvents]);

  return (
    <div>
      <FraudFlagDetail fraudFlags={activeFraudFlags} />
      <div className={`table ${styles['fraud-table']}`}>
        <span className={styles['fraud-table__heading']}>
          <h1>Fraudログ</h1>
        </span>
        <table>
          <thead>
            <tr>
              <th>作成日</th>
              <th>作成者</th>
              <th>メッセージ</th>
            </tr>
          </thead>
          <tbody>
            <PermissionChecker
              placeholder={<NoPermissionViewLog />}
              permissions={[FRAUD_PERMISSION.PAYMENT_FRAUD_FLAGS_READ]}
            >
              {pagedFraudFlagEvents.length ? (
                pagedFraudFlagEvents.map(event => (
                  <FraudFlagLogItem
                    key={event.id}
                    createdAt={event.createdAt}
                    createdBy={event.createdBy}
                    message={event.message}
                  />
                ))
              ) : (
                <tr>
                  <td colSpan={3} className={styles['fraud-table__empty-row']}>
                    Fraudログがありません
                  </td>
                </tr>
              )}
            </PermissionChecker>
          </tbody>
        </table>
        {pageCount > 1 && (
          <Pagination pageCount={pageCount} page={page} gotoPage={setPage} />
        )}
      </div>
    </div>
  );
};

export default FraudFlagLogs;
