import gql from 'graphql-tag';

export const getVAN = gql`
  query VAN($consumerId: ID!) {
    van(consumerId: $consumerId) {
      bankName
      branchName
      branchCode
      accountName
      accountType
      accountNumber
      vanKey
    }
  }
`;

export const assignVAN = gql`
  mutation assignVAN($consumerId: ID!) {
    assignVan(consumerId: $consumerId) {
      bankName
      branchName
      branchCode
      accountName
      accountType
      accountNumber
      vanKey
    }
  }
`;
