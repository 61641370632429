// @flow
import React from 'react';
import { Link } from '@reach/router';

type DetailsProps = {
  consumer: {
    [key: string]: any;
  };
  consumerId?: string;
  hasHeader: boolean;
};

const Details = ({ consumer, consumerId, hasHeader }: DetailsProps) => {
  if (!consumer) {
    return <div className="na-box" />;
  }

  const { dateOfBirth, email, kanaName, kanjiName, phone, address } = consumer;

  const searchParams = hasHeader ? '' : '?header=0';

  return (
    <>
      <div className="table">
        <table>
          <thead>
            <tr>
              <th>氏名（カナ）</th>
              <th>生年月日</th>
              <th>連絡先</th>
              <th>住所</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {/* $FlowFixMe */}
                <Link to={`/accounts/${consumerId}${searchParams}`}>
                  {!kanjiName && !kanaName ? (
                    'N/A'
                  ) : (
                    <>
                      {kanjiName}
                      <br />
                      {kanaName}
                    </>
                  )}
                </Link>
              </td>
              <td>{dateOfBirth || 'Empty'}</td>
              <td>
                {email}
                <br />
                {phone}
              </td>
              <td>
                {address &&
                  `${address.zip || ''} ${address.state || ''} ${address.city ||
                    ''} ${address.line2 || ''} ${address.line1 || ''}`}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Details;
