// @flow
import React, { useState, useEffect } from 'react';
import { Link } from '@reach/router';

import Pagination from '../../Pagination/Pagination';

import {
  getTimeString,
  currencyFormatter,
  pagerize,
  renderPaymentTags,
} from '../../../utils';

type PaidyPaymentsProps = {
  payments: Array<any>;
  updateUIPairs: Function;
};

type PaymentTableProps = { payments: Array<any> };

const PaidyPayments = ({ payments, updateUIPairs }: PaidyPaymentsProps) => {
  useEffect(() => {
    updateUIPairs({ searchType: 'payments' });
  }, []);

  if (!payments || !payments.length) {
    return <div className="na-box" />;
  }

  return <PaymentTable payments={payments} />;
};

const PER_PAGE = 100;

const PaymentTable = ({ payments }: PaymentTableProps) => {
  const [page, setPage] = useState(0);

  if (!payments || !payments.length) {
    return <div className="na-box" />;
  }

  const pageCount = payments ? Math.ceil(payments.length / PER_PAGE) : 0;
  const pagedPayments = pagerize({
    entries: payments,
    page,
    perPage: PER_PAGE,
  });

  const gotoPage = (p: number) => {
    setPage(p);
  };

  return (
    <div className="table">
      {pageCount > 1 && (
        <Pagination
          pageCount={pageCount}
          page={page}
          gotoPage={gotoPage}
          isTop
        />
      )}
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>名前</th>
            <th className="align-right">金額</th>
            <th>住所</th>
            <th>作成日</th>
            <th>ステータス</th>
          </tr>
        </thead>
        <tbody>
          {pagedPayments.map((p, i) => {
            const addr: any = p.shippingAddress;
            const consumerId: string = p.consumerId;

            return (
              <tr key={i}>
                <td className="id">
                  {/* $FlowFixMe */}
                  <Link to={`/payments/${p.id}/auth`}>{p.id}</Link>
                  <br />
                  {renderPaymentTags(p)}
                  {p.test && <span className="tag test">test</span>}
                </td>
                <td className="no-wrap">
                  {consumerId ? (
                    <>
                      {/* $FlowFixMe */}
                      <Link to={`/consumers/${consumerId}/details`}>
                        {p.consumerName || 'N/A'}
                      </Link>
                    </>
                  ) : (
                    <p>N/A</p>
                  )}
                </td>
                <td className="align-right">
                  {!p.amount && p.amount !== 0
                    ? 'N/A'
                    : currencyFormatter.format(p.amount)}
                </td>
                <td>
                  {addr
                    ? `${addr.zip} ${addr.state} ${addr.city} ${addr.line2} ${addr.line1}`
                    : 'N/A'}
                </td>
                <td>
                  <time>{getTimeString(new Date(p.createdAt))}</time>
                </td>
                <td>
                  <span
                    className={`tag ${p.status ? p.status.toLowerCase() : ''}`}
                  >
                    {p.status}
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {pageCount > 1 && (
        <Pagination pageCount={pageCount} page={page} gotoPage={gotoPage} />
      )}
    </div>
  );
};

export default PaidyPayments;
