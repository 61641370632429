import React from 'react';
import { Router, navigate, RouteComponentProps } from '@reach/router';
import { useQuery } from '@apollo/react-hooks';
import idx from 'idx';

import { onError } from '../../utils';
import gqlQuery from './gql-query';

import LoadingPartial from '../Loading/LoadingPartial';
import NavLink from '../NavLink/NavLink';
import Consumers from './Consumers/Consumers';
import PaidyPayments from './PaidyPayments/PaidyPayments';
import CombiniPayments from './CombiniPayments/CombiniPayments';
import Card from '../Card/Card';

type SearchPageProps = {
  searchKeyword: string;
  updateUIPairs: Function;
};

const RouterPage = (
  props: { pageComponent: JSX.Element } & RouteComponentProps
) => props.pageComponent;

const SearchPage = ({ searchKeyword, updateUIPairs }: SearchPageProps) => {
  if (!searchKeyword) {
    return (
      <Card>
        <div className="na-box" />
      </Card>
    );
  }

  // disabled because we want to avoid unnecessary request at first render
  // eslint-disable-next-line
  const { loading, data } = useQuery(gqlQuery, {
    onError,
    variables: { searchKeyword },
  });

  if (loading) {
    return <LoadingPartial />;
  }

  if (!data || !data.fuzzySearch) {
    return (
      <Card>
        <div className="na-box" />
      </Card>
    );
  }

  const { consumers, combiniPayments, paidyPayments } = data.fuzzySearch;
  const exactMatchProps = ['consumers', 'paidyPayments', 'combiniPayments'];

  if (searchKeyword) {
    for (let p = 0; p < exactMatchProps.length; p += 1) {
      const prop = exactMatchProps[p];

      if (
        idx(data, _ => _.fuzzySearch[prop].length) === 1 &&
        searchKeyword === idx(data, _ => _.fuzzySearch[prop][0].id)
      ) {
        let prefix = prop;
        let suffix = 'details';

        switch (prop) {
          case 'paidyPayments': {
            prefix = 'payments';
            suffix = 'auth';

            break;
          }
          case 'combiniPayments': {
            prefix = 'combini-payments';
            suffix = '';

            break;
          }
          default: {
            prefix = prop;

            break;
          }
        }

        navigate(`/${prefix}/${searchKeyword}/${suffix}`);

        return null;
      }
    }
  }

  return (
    <div>
      <Card>
        <nav className="tabs">
          <NavLink title="ペイメント" to="/search/payments">
            ペイメント
          </NavLink>
          <NavLink title="顧客名" to="/search/consumers">
            顧客名
          </NavLink>
          <NavLink title="コンビニ払い" to="/search/combini-payments">
            コンビニ払い
          </NavLink>
        </nav>
        <Router primary={false}>
          <RouterPage
            path="payments"
            pageComponent={
              <PaidyPayments
                payments={paidyPayments}
                updateUIPairs={updateUIPairs}
              />
            }
          />
          <RouterPage
            path="consumers"
            pageComponent={
              <Consumers updateUIPairs={updateUIPairs} consumers={consumers} />
            }
          />
          <RouterPage
            path="combini-payments"
            pageComponent={
              <CombiniPayments
                updateUIPairs={updateUIPairs}
                combiniPayments={combiniPayments}
              />
            }
          />
        </Router>
        {/* <Pagination /> */}
      </Card>
    </div>
  );
};

export default SearchPage;
