import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import idx from 'idx';
import gqlQuery from './gql-query';

import LoadingPartial from '../Loading/LoadingPartial';
import Card from '../Card/Card';

import {
  getTimeString,
  getMonthString,
  currencyFormatter,
  onError,
} from '../../utils';

import { MYPAIDY_HOST } from '../../constants';

import styles from './BillStatementPage.module.scss';

type BillStatementPageProps = {
  billStatementId: string;
  consumerId: string;
};

const BillStatementPage = ({
  billStatementId,
  consumerId,
}: BillStatementPageProps) => {
  const { loading, data } = useQuery(gqlQuery, {
    onError,
    variables: { billStatementId, consumerId },
  });

  if (loading) {
    return <LoadingPartial />;
  }

  if (!data || !data.billstatementsWithSettlements) {
    return <div className="na-box" />;
  }

  const paidyCode = idx(data, _ => _.paidycode.value);
  const statement = data.billstatementsWithSettlements.find(
    (_: any) => _.billStatement.id === billStatementId
  );
  const bill = idx(statement, _ => _.billStatement);
  const autoDebit = idx(statement, _ => _.autoDebitSettlement);
  const combini = idx(statement, _ => _.combiniSettlement);

  if (!bill) {
    return <div className="na-box" />;
  }

  return (
    <>
      <Card>
        <h3 className={styles.title}>
          請求明細
          <span
            className={`tag ${bill.status ? bill.status.toLowerCase() : ''}`}
          >
            {bill.status}
          </span>
          {paidyCode && (
            <a
              className={styles.code}
              href={`${MYPAIDY_HOST}/s/${paidyCode}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Paidy Code
            </a>
          )}
        </h3>
        <div className={styles.grid}>
          <div className={styles.cell}>
            <span className={styles.label}>請求月</span>
            <span className={styles.value}>
              {getMonthString(new Date(bill.issueDate))}
            </span>
          </div>
          <div className={styles.cell}>
            <span className={styles.label}>発行日</span>
            <span className={styles.value}>
              {getTimeString(new Date(bill.issueDate))}
            </span>
          </div>
          <div className={styles.cell}>
            <span className={styles.label}>支払期日</span>
            <span className={styles.value}>
              {getTimeString(new Date(bill.dueAt))}
            </span>
          </div>
          <div className={styles.cell}>
            <span className={styles.label}>請求額</span>
            <span className={styles.value}>
              {currencyFormatter.format(bill.amount)}
            </span>
          </div>
          <div className={styles.cell}>
            <span className={styles.label}>未払い請求額</span>
            <span className={styles.value}>
              {currencyFormatter.format(bill.unpaidAmount)}
            </span>
          </div>
          <div className={styles.cell}>
            <span className={styles.label}>遅延手数料</span>
            <span className={styles.value}>
              {currencyFormatter.format(bill.lateFeesAmount)}
            </span>
          </div>
        </div>
        {autoDebit && (
          <>
            <h4>口座振替明細</h4>
            <div className="table striped">
              <table>
                <tbody>
                  <tr>
                    <th style={{ width: 175 }}>引落金額</th>
                    <td>{currencyFormatter.format(autoDebit.pullAmount)}</td>
                  </tr>
                  <tr>
                    <th>引落結果</th>
                    <td>
                      <span
                        className={`tag ${
                          autoDebit.pullStatus
                            ? autoDebit.pullStatus.toLowerCase()
                            : ''
                        }`}
                      >
                        {autoDebit.pullStatus}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th>支払期限</th>
                    <td>
                      {getTimeString(new Date(autoDebit.resultsProcessedBy))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
        {combini && (
          <>
            <h4>コンビニ支払い詳細</h4>
            <div className="table striped">
              <table>
                <tbody>
                  <tr>
                    <th style={{ width: 175 }}>コンビニペイメントID </th>
                    <td>{combini.combiniPaymentId}</td>
                  </tr>
                  <tr>
                    <th style={{ width: 175 }}>金額</th>
                    <td>{currencyFormatter.format(combini.amount)}</td>
                  </tr>
                  <tr>
                    <th style={{ width: 175 }}>支払手数料</th>
                    <td>
                      {currencyFormatter.format(combini.settlementFeeAmount)}
                    </td>
                  </tr>
                  <tr>
                    <th>Code</th>
                    <td>
                      {`${combini.consumerCode}, ${combini.merchantCode}`}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
      </Card>
    </>
  );
};

export default BillStatementPage;
