import React from 'react';

import AssessmentTable from './AssessmentTable';
import FraudEventLogsTable from './FraudEventLogsTable';
import FraudFlagsTable from './FraudFlagsTable';

const Fraud = () => {
  return (
    <>
      <FraudFlagsTable />
      <br />
      <br />
      <br />
      <AssessmentTable />
      <br />
      <br />
      <br />
      <FraudEventLogsTable />
    </>
  );
};

export default Fraud;
