// @flow
import React from 'react';

import { getTimeString, currencyFormatter } from '../../../utils';

type RefundType = {
  id: string;
  amount: number;
  captureId: string;
  createdAt: number;
  currency: string;
  reason: string;
};

type RefundsProps = { refunds: Array<RefundType> };

const Refunds = ({ refunds }: RefundsProps) => {
  if (!refunds || !refunds.length) {
    return <div className="na-box" />;
  }

  return (
    <>
      <div className="table">
        <table>
          <thead>
            <tr>
              <th>キャプチャーID</th>
              <th className="align-right">金額</th>
              <th>理由</th>
              <th>作成日</th>
            </tr>
          </thead>
          <tbody>
            {refunds.map((r, i) => {
              return (
                <tr key={i}>
                  <td className="bold">{r.captureId}</td>
                  <td className="align-right">
                    {currencyFormatter.format(r.amount)}
                  </td>
                  <td>{r.reason}</td>
                  <td>
                    <time>{getTimeString(new Date(r.createdAt))}</time>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Refunds;
