import React from 'react';

export const LoadingMessage = ({ loading }: { loading: boolean }) => {
  if (loading) return <div>処理中です</div>;

  return null;
};

export const ErrorMessage = ({ error }: { error: any }) => {
  if (error) {
    return (
      <div>
        エラーが発生しました
        <br />
        <br />
        {JSON.stringify(error)}
      </div>
    );
  }

  return null;
};
