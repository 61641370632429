// @flow
import React from 'react';

import styles from './LoginPage.module.scss';

const SuccessLogin = () => {
  return (
    <div className={styles['success-login']}>
      <p>ログインに成功しました</p>
    </div>
  );
};

export default SuccessLogin;
