import gql from 'graphql-tag';

export interface PaymentFraudFlagEvent {
  id: string;
  createdAt: number;
  createdBy: string;
  message: string;
}

export const getPaymentFraudFlagsHistory = gql`
  query paymentFraudFlagsHistory($paymentId: ID!) {
    paymentFraudFlagsHistory(paymentId: $paymentId) {
      fraudFlagEvents {
        id
        createdBy
        createdAt
        message
      }
    }
  }
`;
