import gql from 'graphql-tag';

export const query = gql`
  fragment linkEvent on LinkEvent {
    status
    time
  }

  fragment paidyLink on PaidyLink {
    status
    linkEvents {
      ...linkEvent
    }
    gpsToken
  }

  query PaidyLink($consumerId: ID!) {
    paypalLinks(consumerId: $consumerId) {
      ...paidyLink
    }
  }
`;

export const gpsQuery = gql`
  query gpsTokens($consumerId: ID!) {
    gpsTokens(consumerId: $consumerId) {
      token
      status
      createdAt
      idemiaId
      physicalCardStatus
      physicalCardUpdatedAt
      physicalCardEvents {
        physicalCardStatus
        physicalCardUpdatedAt
      }
    }
  }
`;

export const cardEligibilityQuery = gql`
  query cardEligibility($consumerId: ID!) {
    cardEligibility(consumerId: $consumerId) {
      isEligible
    }
  }
`;

export const createCardEligibilityException = gql`
  mutation createCardEligibilityException($consumerId: ID!) {
    createCardEligibilityException(consumerId: $consumerId)
  }
`;

export const updateGpsTokenStatus = gql`
  mutation updateGpsTokenStatus(
    $consumerId: ID!
    $token: ID!
    $status: String!
  ) {
    updateGpsTokenStatus(
      consumerId: $consumerId
      token: $token
      status: $status
    )
  }
`;

export const updatePhysicalCardStatus = gql`
  mutation updatePhysicalCardStatus(
    $consumerId: ID!
    $token: ID!
    $physicalCardStatus: String!
  ) {
    updatePhysicalCardStatus(
      consumerId: $consumerId
      token: $token
      status: $physicalCardStatus
    )
  }
`;
