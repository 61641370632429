import React, { useState, ReactNode } from 'react';
import { Link } from '@reach/router';

import './Tabs.scss';

interface TabData {
  key: string;
  title: string;
  disabled?: boolean;
  content: () => ReactNode | null;
}

interface TabsProps {
  tabs: TabData[];
  basePath: string;
  defaultTab?: string;
}

const Tab = ({
  tabData,
  isActive,
}: {
  tabData: TabData;
  isActive: boolean;
}) => {
  const { content: renderContent } = tabData;

  return isActive ? <div className="tab-content">{renderContent()}</div> : null;
};

const getTabHeaderClassName = (activeTab: string, tab: TabData) => {
  const baseClass = 'tab-header';
  const activeClass = activeTab === tab.key ? 'tab-header--active' : '';
  const disabledClass = tab.disabled ? 'tab-header--disabled' : '';
  return `${baseClass} ${activeClass} ${disabledClass}`;
};

const Tabs: React.FC<TabsProps> = ({ tabs, basePath, defaultTab }) => {
  const [activeTab, setActiveTab] = useState(defaultTab || tabs[0].key);

  const handleTabClick = (tabKey: string) => {
    setActiveTab(tabKey);
  };

  return (
    <div className="tabs-wrapper">
      <div className="tabs-wrapper__header">
        {tabs.map(tab => (
          <Link
            key={tab.key}
            to={`/${basePath}/${tab.key}`}
            state={{ key: tab.key }}
            onClick={() => handleTabClick(tab.key)}
            className={getTabHeaderClassName(activeTab, tab)}
          >
            {tab.title}
          </Link>
        ))}
      </div>
      <div className="tabs-wrapper__content">
        {tabs.map(tab => (
          <Tab key={tab.key} tabData={tab} isActive={activeTab === tab.key} />
        ))}
      </div>
    </div>
  );
};

export default Tabs;
