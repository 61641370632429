import React, { FC } from 'react';

import { getTimeString } from '../../../utils';

export interface FraudFlagLogItemProps {
  createdAt: number;
  createdBy: string;
  message: string;
}

const FraudFlagLogItem: FC<FraudFlagLogItemProps> = ({
  createdAt,
  createdBy,
  message,
}) => {
  return (
    <tr>
      <td className="no-wrap">{getTimeString(new Date(createdAt))}</td>
      <td>{createdBy}</td>
      <td>{message}</td>
    </tr>
  );
};

export default FraudFlagLogItem;
