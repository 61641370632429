// @flow
import { connect } from 'react-redux';

import LoginPage from './LoginPage';
import { updateUIToBeCachedPairs } from '../../redux/ui-to-be-cached';

const mapStateToProps = (state: any) => state;
const mapDispatchToProps = (dispatch: Function) => ({
  updateUIToBeCachedPairs: (pairs: any) =>
    dispatch(updateUIToBeCachedPairs(pairs)),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
