import React from 'react';

type PacDataRowsProps = {
  plusData: { [key: string]: any };
};

export const PacDataRows = ({ plusData }: PacDataRowsProps) => {
  const pacData = plusData && plusData.pacData;
  const annualIncome = (pacData && pacData.annualIncome) || '';
  const dependents = (pacData && pacData.dependents) || '';
  const rentOrMortgage = (pacData && pacData.rentOrMortgage) || '';

  return (
    <>
      <tr>
        <th>年収</th>
        <td>{annualIncome}</td>
      </tr>
      <tr>
        <th>扶養家族</th>
        <td>{dependents}</td>
      </tr>
      <tr>
        <th>家賃もしくは住宅ローンの支払い</th>
        <td>{rentOrMortgage}</td>
      </tr>
    </>
  );
};
