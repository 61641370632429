import gql from 'graphql-tag';

export const cancelContracts = gql`
  mutation cancelContracts($installmentPlanIds: [ID!]!) {
    cancelContracts(installmentPlanIds: $installmentPlanIds, test: false) {
      id
      status
      code
      title
      description
    }
  }
`;
