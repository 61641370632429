import React, { useContext, useState } from 'react';
import CustomModal from '../../Modal/Modal';

type UnmountableCustomModalProviderProps = {
  children: React.ReactNode;
};

type UnmountableModalWrapperProps = {
  children: React.ReactNode;
  className?: string;
};

export const UnmountableCustomModalContext = React.createContext({
  isModalOpen: false,
  onConfirm: () => null,
  onCancel: () => null,
  onOpen: () => null,
});

export const useUnmountableCustomModalContext = () =>
  useContext(UnmountableCustomModalContext);

export const UnmountableCustomModalProvider = ({
  children,
}: UnmountableCustomModalProviderProps) => {
  const [isModalOpen, setModalDisplayed] = useState(false);

  const onConfirm = () => {
    setModalDisplayed(false);
    return null;
  };

  const onCancel = () => {
    setModalDisplayed(false);
    return null;
  };

  const onOpen = () => {
    setModalDisplayed(true);
    return null;
  };

  return (
    <UnmountableCustomModalContext.Provider
      value={{ isModalOpen, onConfirm, onCancel, onOpen }}
    >
      {children}
    </UnmountableCustomModalContext.Provider>
  );
};

export const UnmountableModalWrapper = ({
  children,
  className = '',
}: UnmountableModalWrapperProps) => {
  const { isModalOpen } = useUnmountableCustomModalContext();

  if (!isModalOpen) return null;

  return (
    <CustomModal isOpen={isModalOpen} className={className}>
      {children}
    </CustomModal>
  );
};
