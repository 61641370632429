import React, { useRef } from 'react';
import './FileSelector.scss';

interface FileSelectorProps {
  onFileSelect: (file: File | null) => void;
  fileName?: string;
}

const FileSelector: React.FC<FileSelectorProps> = ({
  onFileSelect,
  fileName,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;

    onFileSelect(file);
  };

  const handleChooseClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      fileInputRef.current.click();
    }
  };

  return (
    <div className="file-select">
      <span
        className="file-select__button btn primary"
        onClick={handleChooseClick}
      >
        Choose
      </span>
      <span className="file-select__filename">
        {fileName || 'No file selected'}
      </span>
      <input
        type="file"
        accept=".csv"
        className="file-select__input"
        onChange={handleFileChange}
        ref={fileInputRef}
      />
    </div>
  );
};

export default FileSelector;
