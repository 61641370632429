import React, { SyntheticEvent, useState } from 'react';

import './ReportSetting.scss';
import FormatErrorPopup from './ValidationPopups/FormatErrorPopup/FormatErrorPopup';
import FileSelector from '../../../Fields/FileSelector/FileSelector';
import SubmittedPopup from './ValidationPopups/SubmittedPopup/SubmittedPopup';
import { parseCsvLine, validateCsvData } from './ValidationPopups/helpers';
import { uploadDeactivationList } from '../../gql-query';
import { useMutation } from 'react-apollo';

export type ErrorType = { message: string; value: string; for?: string } | null;
export type CsvRecord = {
  merchantId: string;
  year: number;
  month: number;
};
enum GQLStatus {
  Success = 'Success',
  Failure = 'Failure',
}

const ReportSettingTabContent = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewData, setPreviewData] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<ErrorType[]>([]);
  const [submittedDescription, setSubmittedDescription] = useState<
    string | null
  >(null);
  const [csvData, setCsvData] = useState<CsvRecord[]>([]);
  const [deactivateInvoices] = useMutation(uploadDeactivationList);

  const onFileSelect = (file: File | null) => {
    if (file) {
      setSelectedFile(file);
      handleCsvParse(file);
    }
  };

  const handleCloseErrorModal = () => {
    setErrorMsg([]);
    setPreviewData('');
    setSelectedFile(null);
  };

  const handleCloseSubmittedModal = () => {
    handleCloseErrorModal();
    setSubmittedDescription(null);
  };

  const handleCsvParse = (file: File) => {
    const reader = new FileReader();

    reader.onload = event => {
      const contents = event.target?.result as string;
      const lines = contents.split('\n');

      const validationError = validateCsvData(lines);
      if (validationError.length) {
        setErrorMsg(validationError);
        return;
      }

      lines.shift();
      const records = lines.map(parseCsvLine);
      setCsvData(records);
      setPreviewData(lines.join('\n'));
    };

    reader.readAsText(file);
  };

  const handleSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    deactivateInvoices({
      variables: {
        invoicesToDeactivate: csvData,
      },
    }).then(({ data: { deactivateInvoices } }) => {
      if (deactivateInvoices.status === GQLStatus.Failure) {
        setSubmittedDescription(
          'There was a system error processing your request, please try again later.'
        );
      } else if (deactivateInvoices.status === GQLStatus.Success) {
        setSubmittedDescription('Operation completed successfully.');
      } else {
        setSubmittedDescription('An unexpected error occurred.');
      }
    });
  };

  return (
    <div className="report-setting">
      <h3>Report setting</h3>
      <form onSubmit={handleSubmit}>
        <fieldset>
          <label>
            Please upload the csv file to <span>Deactivate</span> the PDF
            download button
          </label>
          <FileSelector
            onFileSelect={onFileSelect}
            fileName={selectedFile?.name}
          />
        </fieldset>
        <div>
          <label>Full record from the uploaded csv</label>
          <pre>{previewData}</pre>
        </div>
        <div>
          <input
            type="submit"
            className="btn primary"
            value="Save settings"
            disabled={!selectedFile}
          />
        </div>
        <FormatErrorPopup
          errorMsg={errorMsg}
          handleClose={handleCloseErrorModal}
        />
        <SubmittedPopup
          description={submittedDescription}
          handleClose={handleCloseSubmittedModal}
        />
      </form>
    </div>
  );
};

export default ReportSettingTabContent;
