// @flow
import gql from 'graphql-tag';

// TOFIX: type should be consistent, as in ID!
export default gql`
  query AdminLogs($consumerId: ID!) {
    adminlogs(consumerId: $consumerId, test: false) {
      message
      creator
      createdAt
    }
  }
`;
