import { useMutation, useQuery } from '@apollo/react-hooks';
import { useParams } from '@reach/router';
import gql from 'graphql-tag';
import React from 'react';
import { plusstatusFragment } from '../gql-query';
import { useUnmountableCustomModalContext } from './UnmountableCustomModal';

export const mutationDoc = gql`
  mutation resetConsumerStatus($consumerId: ID!) {
    resetConsumerStatus(consumerId: $consumerId)
  }
`;

export const queryDoc = gql`
  query PlusStatus($consumerId: ID!) {
    plusstatus(consumerId: $consumerId) {
      ...plusstatus
    }
  }
  ${plusstatusFragment}
`;

const mapKYCStatus = (status: string): string => {
  switch (status) {
    case 'never_applied':
      return 'eKYC未申請';
    case 'pending':
      return '処理中';
    case 'approved':
      return 'eKYC承認';
    case 'rejected':
      return 'eKYC否決';
    default:
      return 'INVALID_STATUS';
  }
};

const mapHoukatsuStatus = (status: string): string => {
  switch (status) {
    case 'never_started':
      return '包括未申請';

    case 'not_pep_declared':
      return 'PEP確認中';

    case 'awaiting_cic_results':
      return 'CICデータ取得中';

    case 'pac_data_required':
      return 'PACデータ待ち';

    case 'awaiting_pac_assessment':
      return 'PAC審査処理中';

    case 'approved':
      return '包括承認';

    case 'closed':
      return '包括解約';

    case 'canceled':
      return '包括キャンセル済み';

    case 'rejected':
      return '包括否決';

    case 'approved_for_nlp':
      return 'NLP承認';

    default:
      return 'INVALID_STATUS';
  }
};

const mapAppleStatus = (status: string): string => {
  switch (status) {
    case 'never_started':
      return 'APPLE未申請';

    case 'opt_in_declared':
      return 'APPLE申請確認中';

    case 'awaiting_cic_results':
      return 'CICデータ取得中';

    case 'pac_data_required':
      return 'PACデータ待ち';

    case 'awaiting_pac_assessment':
      return 'PAC審査処理中';

    case 'approved':
      return 'APPLE承認';

    case 'closed':
      return 'APPLE解約';

    case 'canceled':
      return 'APPLEキャンセル済み';

    case 'rejected':
      return 'APPLE否決';

    default:
      return 'INVALID_STATUS';
  }
};

const ResetHoukatsuStatusContext = React.createContext({
  isModalOpen: false,
  inactive: false,
  onConfirm: () => null,
  onCancel: () => null,
  onOpen: () => null,
  mappedHoukatsuStatus: '',
  mappedEKYCStatus: '',
  mappedAppleStatus: '',
  consumerId: '',
});

export const useResetHoukatsuStatusContext = () =>
  React.useContext(ResetHoukatsuStatusContext);

export const ResetHoukatsuStatusProvider = ({
  children,
}: {
  children: any;
}) => {
  const { consumerId } = useParams();
  const {
    onCancel,
    onConfirm,
    onOpen,
    isModalOpen,
  } = useUnmountableCustomModalContext();

  const query = useQuery(queryDoc, { variables: { consumerId } });

  const ekycStatus =
    (query.data && query.data.plusstatus && query.data.plusstatus.ekycStatus) ||
    '';
  const houkatsuStatus =
    (query.data &&
      query.data.plusstatus &&
      query.data.plusstatus.houkatsuStatus) ||
    '';
  const appleStatus =
    (query.data &&
      query.data.plusstatus &&
      query.data.plusstatus.appleStatus) ||
    '';

  const active = ekycStatus === 'approved' && houkatsuStatus === 'rejected';
  const inactive = !active;

  const mappedEKYCStatus = mapKYCStatus(ekycStatus);
  const mappedHoukatsuStatus = mapHoukatsuStatus(houkatsuStatus);
  const mappedAppleStatus = mapAppleStatus(appleStatus);

  if (!consumerId) return null;

  return (
    <ResetHoukatsuStatusContext.Provider
      value={{
        inactive,
        isModalOpen,
        onCancel,
        onConfirm,
        onOpen,
        mappedEKYCStatus,
        mappedHoukatsuStatus,
        mappedAppleStatus,
        consumerId,
      }}
    >
      {children}
    </ResetHoukatsuStatusContext.Provider>
  );
};

export const useResetKYCStatus = (
  consumerId: string,
  onCompleted: () => void
) =>
  useMutation(mutationDoc, {
    variables: {
      consumerId,
    },
    onCompleted,
    refetchQueries: [{ query: queryDoc, variables: { consumerId } }],
    awaitRefetchQueries: true,
  });
