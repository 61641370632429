// @flow
import React from 'react';
import idx from 'idx';
import { navigate } from '@reach/router';

import { store } from '../index';
import { updateUIToBeCachedPairs } from '../redux/ui-to-be-cached';

export const onError = (error: object) => {
  const statusCode = idx(error, _ => _.networkError.statusCode);

  if (statusCode === 401) {
    navigate('/login');

    if (store) {
      store.dispatch(updateUIToBeCachedPairs({ userId: undefined }));
    }
  }
};

export const getTimeString = (d: any): string => {
  return d
    .toLocaleDateString('ja-JP', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })
    .replace(/\//g, '-'); // convert / to -
};

export const getDateString = (d: any): string => {
  return d
    .toLocaleDateString('ja-JP', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .replace(/\//g, '-'); // convert / to -;
};

export const getMonthString = (d: any): string => {
  return d.toLocaleDateString('ja-JP', {
    year: 'numeric',
    month: '2-digit',
  });
};

export const getPrevMonth = (d: any): string => {
  return getMonthString(
    new Date(d.getFullYear(), d.getMonth() - 1, d.getDate())
  );
};

export const currencyFormatter = (() => {
  if (Intl !== undefined && Intl.NumberFormat) {
    return new Intl.NumberFormat('ja-JP', {
      style: 'currency',
      currency: 'JPY',
    });
  }

  return {
    format(input: number) {
      return `¥${input}`;
    },
  };
})();

export const pagerize = ({
  entries,
  page,
  perPage,
}: {
  entries: ?Array<any>,
  page: number,
  perPage: number,
}): Array<any> => {
  if (!entries) {
    return [];
  }

  return entries.slice(page * perPage, page * perPage + perPage);
};

export const isEmpty = (input: string): boolean =>
  !input || typeof input !== 'string' || !input.trim().length;

const EMPTY_MESSAGE = '必須項目です';
const STRING_TOOLONG_MESSAGE = 'String length exceed limit';

export const validateEmail = (email: string): string => {
  const re = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;

  if (isEmpty(email)) {
    return EMPTY_MESSAGE;
  }

  if (!re.test(email)) {
    return 'メールアドレスを正しく入力してください';
  }

  return '';
};

export const validateEmptyString = (str: string): string => {
  if (isEmpty(str)) {
    return EMPTY_MESSAGE;
  }

  return '';
};

export const validateStringLength = (len: number) => (str: string): string => {
  if (str && str.length && str.length > len) {
    return STRING_TOOLONG_MESSAGE;
  }

  return '';
};

export const validateSMS = (str: string): string => {
  return validateEmptyString(str) || validateStringLength(128)(str) || '';
};

export const validateAdminlog = (log: string): string => {
  if (isEmpty(log)) {
    return EMPTY_MESSAGE;
  }

  return '';
};

export const renderPaymentTags = (payment: object) => {
  if (
    payment.tags &&
    !!payment.tags.length &&
    payment.tags.indexOf('tatekae') > -1
  ) {
    return <span className="tag big">BIG</span>;
  }

  if (payment.tier && payment.tier.toLowerCase() !== 'classic') {
    return (
      <span className={`tag ${payment.tier.toLowerCase()}`}>
        {payment.tier}
      </span>
    );
  }

  return null;
};

export const capitalize = text => {
  return text[0].toUpperCase() + text.substring(1);
};
