import { connect } from 'react-redux';

import SearchPage from './SearchPage';

import { updateUIPairs } from '../../redux/ui';

const mapStateToProps = (state: any) => ({
  searchKeyword: state.ui.searchKeyword,
});

const mapDispatchToProps = (dispatch: Function) => ({
  updateUIPairs: (pairs: unknown) => dispatch(updateUIPairs(pairs)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);
