import gql from 'graphql-tag';

export default gql`
  query PaymentPage($paymentId: ID!) {
    paymentMessages(paymentId: $paymentId) {
      subject
      message
      createdAt
      kind
      dispatched
    }
    payment(id: $paymentId) {
      id
      basketId
      appleOrderNumber
      description
      consumerId
      consumerName
      merchantName
      amount
      currency
      capturedAmount
      refundedAmount
      captures {
        id
        createdAt
        amount
        currency
      }
      refunds {
        id
        captureId
        createdAt
        amount
        currency
        reason
      }
      contractData {
        firstName
        lastName
        annualIncome
        dateOfBirth
        gender
        maritalStatus
        householdSize
        mortgage
        employer
        contractAddress {
          line1
          line2
          city
          state
          zip
        }
        residenceType
      }
      buyer {
        name1
        name2
        email
        phone
      }
      consumerData {
        email
        phone
        name1
        name2
        address {
          zip
          line1
          line2
          state
          city
        }
      }
      order {
        tax
        shipping
        orderRef
        items {
          id
          title
          description
          unitPrice
          quantity
        }
        updatedAt
      }
      status
      tier
      tags
      updatedAt
      createdAt
      expiresAt
      test
      isSpecifiedContinuousServiceMerchant
      shippingAddress {
        zip
        line1
        line2
        state
        city
      }
      billingAddress {
        zip
        line1
        line2
        state
        city
      }
      buyerDataJson
    }
    paymentAssessmentReportsByPaymentId(paymentId: $paymentId) {
      reports {
        id
        paymentId
        kind
        result
        reason
        metadataJson
      }
      error {
        kind
        description
      }
    }
  }
`;

export interface PaymentFraudFlag {
  id: string;
  flaggedFor:
    | string
    | {
        id: string;
        description: string;
      };
  flaggedBy: string;
  flaggedAt: string;
  excludeFromBill: boolean;
}

export const getPaymentActiveFraudFlags = gql`
  query activePaymentFraudFlags($paymentId: ID!) {
    activePaymentFraudFlags(paymentId: $paymentId) {
      fraudStatus {
        excludeFromBill
        activeFraudFlags {
          id
          flaggedFor {
            id
            description
          }
          flaggedBy
          flaggedAt
          excludeFromBill
        }
      }
    }
  }
`;
