import { useMutation } from '@apollo/react-hooks';
import { cancelContracts } from '../gql-query/cancel-contracts';

type CancelResponseType = {
  cancelContracts: [];
};

export const useBulkCancel = (
  ids: string[],
  onCompleted: (response: CancelResponseType) => void,
  onError: (error: any) => void
) => {
  return useMutation(cancelContracts, {
    variables: { installmentPlanIds: ids },
    onCompleted,
    onError,
  });
};
