import React, { FC } from 'react';
import { Link } from '@reach/router';

import {
  getTimeString,
  currencyFormatter,
  renderPaymentTags,
} from '../../../utils';
import { ConsumerPayment } from '../../../types/payment';

interface FraudPaymentsItemProps {
  payment: ConsumerPayment;
  searchParams: string;
  isChecked: boolean;
  isFraud: boolean;
  disabled?: boolean;
  onChange: (paymentId: string) => void;
}

const FraudPaymentsItem: FC<FraudPaymentsItemProps> = ({
  payment,
  searchParams,
  isChecked = false,
  isFraud = false,
  disabled = false,
  onChange,
}) => {
  const addr = payment.shippingAddress || payment.billingAddress;

  return (
    <tr>
      <td>
        <input
          type="checkbox"
          aria-label={`select payment ${payment.id}`}
          checked={isChecked}
          disabled={disabled}
          onChange={() => onChange(payment.id)}
        />
      </td>
      <td className="id no-wrap">
        <Link to={`/payments/${payment.id}/auth${searchParams}`}>
          {payment.id}
        </Link>
        <br />
        {renderPaymentTags(payment)}
        {payment.test && <span className="tag test">test</span>}
      </td>
      <td className="no-wrap">{payment.consumerName || 'N/A'}</td>
      <td>
        {addr &&
          `${addr.zip || ''} ${addr.state || ''} ${addr.city ||
            ''} ${addr.line2 || ''} ${addr.line1 || ''}`}
      </td>
      <td className="align-right">
        {currencyFormatter.format(payment.amount)}
      </td>
      <td>
        <time>{getTimeString(new Date(payment.createdAt))}</time>
      </td>
      <td>
        <span
          className={`tag ${
            payment.status ? payment.status.toLowerCase() : ''
          }`}
        >
          {payment.status}
        </span>
      </td>
      <td className="align-right">
        {!payment.captures || !payment.captures.length ? (
          <span className="tag uncaptured">uncaptured</span>
        ) : (
          currencyFormatter.format(payment.capturedAmount)
        )}
      </td>
      <td className="align-right">
        {currencyFormatter.format(payment.refundedAmount)}
      </td>
      <td className="text-center">
        {isFraud ? (
          <>
            <span className="tag red">FRAUDULENT</span>
            <Link to={`/payments/${payment.id}/fraud`}>詳細</Link>
          </>
        ) : (
          'なし'
        )}
      </td>
    </tr>
  );
};

export default FraudPaymentsItem;
