import React, { ReactNode } from 'react';
import { Link } from '@reach/router';
import cx from 'classnames';

type NavLinkProps = {
  activeClassName: string;
  className?: string;
  to: string;
  title: string;
  children: ReactNode;
};

const NavLink = ({
  activeClassName,
  className,
  to,
  title,
  children,
}: NavLinkProps) => {
  return (
    <>
      {/* $FlowFixMe */}
      <Link
        title={title}
        to={to}
        className={className}
        getProps={({ isCurrent }) => {
          // the object returned here is passed to the
          // anchor element's props
          return {
            className:
              isCurrent && activeClassName
                ? cx(className, activeClassName)
                : className,
          };
        }}
      >
        {children}
      </Link>
    </>
  );
};

NavLink.defaultProps = {
  activeClassName: 'active',
  className: '',
};

export default NavLink;
