// @flow
import { connect } from 'react-redux';

import App from './App';

import { updateUIPairs } from './redux/ui';
import { updateUIToBeCachedPairs } from './redux/ui-to-be-cached';

const mapStateToProps = (state: object) => ({
  searchKeyword: state.ui.searchKeyword,
  userId: state.uiToBeCached.userId,
  searchType: state.ui.searchType,
  messages: state.ui.messages,
});

const mapDispatchToProps = (dispatch: Function) => ({
  updateUIPairs: pairs => dispatch(updateUIPairs(pairs)),
  updateUIToBeCachedPairs: pairs => dispatch(updateUIToBeCachedPairs(pairs)),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(App);
