import React, { useState, useEffect } from 'react';
import { useBulkCancel } from '../../utils/hooks/use-bulk-cancel';
import styles from './InstallmentPlanCancelPage.module.scss';

import {
  InstallmentPlanType,
  CancellationResultType,
} from '../../types/installmentPlan';

type CancellationResultProps = {
  installmentPlanGroup: InstallmentPlanType[];
  groupIndex: number;
};

enum Status {
  Success = 'Success',
  NotActive = 'NotActive',
  Failure = 'Failure',
}

const renderStatus = (status: Status) => {
  switch (status) {
    case Status.Success:
      return <div className="tag created">{status}</div>;
    case Status.NotActive:
      return <div className="tag test">Not Active</div>;
    case Status.Failure:
      return <div className="tag red">{status}</div>;
    default:
      return <div className="tag gray">{status}</div>;
  }
};

const renderDescription = (
  status: Status,
  title: string,
  description: string
) => {
  switch (status) {
    case Status.Success:
      return 'Canceled successfully';
    case Status.NotActive:
    case Status.Failure:
    default:
      return (
        <>
          <div>{title}</div>
          <div>{description}</div>
        </>
      );
  }
};

const mapResultToInstallmentPlan = (
  installmentPlanGroup: InstallmentPlanType[],
  cancellationResult: any
): CancellationResultType[] => {
  return installmentPlanGroup.map((plan: InstallmentPlanType) => {
    const result: CancellationResultType = cancellationResult.find(
      (result: CancellationResultType) => result.id === plan.id
    );

    return {
      order: plan.order,
      id: plan.id,
      status: result.status,
      code: result.code || '',
      title: result.title || '',
      description: result.description || '',
    };
  });
};

const CancellationResult = ({
  installmentPlanGroup,
  groupIndex,
}: CancellationResultProps) => {
  const ids = installmentPlanGroup.map((plan: InstallmentPlanType) => plan.id);
  const [result, setResult] = useState<CancellationResultType[]>([]);
  const [error, setError] = useState<any>('');

  const onCompleted = (response: any) => {
    const cancellationResult = response?.cancelContracts;

    if (Array.isArray(cancellationResult)) {
      const mappedResult = mapResultToInstallmentPlan(
        installmentPlanGroup,
        cancellationResult
      );
      setResult(mappedResult);
    } else {
      setError(cancellationResult);
    }
  };

  const onError = (error: any) => {
    setError(error);
  };

  const [mutate, { loading }] = useBulkCancel(ids, onCompleted, onError);

  useEffect(() => {
    setTimeout(() => {
      mutate();
    }, 5500 * groupIndex); // Delay the process for each group
  }, []);

  if (error) {
    return (
      <tr>
        <td colSpan={4}>
          {error?.message || 'Unknown Error: Bulk Cancelation Failed'}
        </td>
      </tr>
    );
  }

  if (loading) {
    return (
      <>
        {installmentPlanGroup.map((data: any) => (
          <tr key={data.id}>
            <td>{data.order}</td>
            <td>{data.id}</td>
            <td>
              <div className={styles['loading']} />
            </td>
            <td>
              <div className={styles['loading']} />
            </td>
          </tr>
        ))}
      </>
    );
  }

  return (
    <>
      {result.map((data: any) => (
        <tr key={data.id}>
          <td>{data.order}</td>
          <td>{data.id}</td>
          <td>{renderStatus(data.status)}</td>
          <td>
            {renderDescription(data.status, data.title, data.description)}
          </td>
        </tr>
      ))}
    </>
  );
};

export default CancellationResult;
