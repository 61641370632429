// @flow
import React, { useState } from 'react';
import { Link } from '@reach/router';

import Pagination from '../../Pagination/Pagination';

import {
  getTimeString,
  currencyFormatter,
  pagerize,
  renderPaymentTags,
} from '../../../utils';

type ChargeType = {
  id: string;
};

type AddressType = {
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  zip: string;
};

type PaymentType = {
  shippingAddress?: AddressType;
  billingAddress?: AddressType;
  amount: number;
  capturedAmount: number;
  captures: Array<ChargeType>;
  consumerName: string;
  createdAt: number;
  id: string;
  refundedAmount: number;
  status: string;
  tags: Array<string>;
  test: boolean;
};

type PaymentsProps = {
  payments?: Array<PaymentType>;
  hasHeader: boolean;
};

const PER_PAGE = 100;

const Payments = ({ payments, hasHeader }: PaymentsProps) => {
  const [page, setPage] = useState(0);

  if (!payments || !payments.length) {
    return <div className="na-box" />;
  }

  const pageCount = payments ? Math.ceil(payments.length / PER_PAGE) : 0;
  const pagedPayments = pagerize({
    entries: payments,
    page,
    perPage: PER_PAGE,
  });

  const gotoPage = (p: number) => {
    setPage(p);
  };

  const searchParams = hasHeader ? '' : '?header=0';

  return (
    <>
      {pageCount > 1 && (
        <Pagination
          pageCount={pageCount}
          page={page}
          gotoPage={gotoPage}
          isTop
        />
      )}
      <div className="table">
        <table>
          <thead>
            <tr>
              <th>ペイメントID</th>
              <th>氏名</th>
              <th>住所</th>
              <th className="align-right">金額</th>
              <th>加盟店</th>
              <th>作成日</th>
              <th>ステータス</th>
              <th className="align-right">キャプチャー金額</th>
              <th className="align-right">リファンド</th>
            </tr>
          </thead>
          <tbody>
            {pagedPayments.map((p, i) => {
              const addr: AddressType = p.shippingAddress || p.billingAddress;

              return (
                <tr key={i}>
                  <td className="id">
                    {/* $FlowFixMe */}
                    <Link to={`/payments/${p.id}/auth${searchParams}`}>
                      {p.id}
                    </Link>
                    <br />
                    {renderPaymentTags(p)}
                    {p.test && <span className="tag test">test</span>}
                  </td>
                  <td className="no-wrap">{p.consumerName || 'N/A'}</td>
                  <td>
                    {addr &&
                      `${addr.zip || ''} ${addr.state || ''} ${addr.city ||
                        ''} ${addr.line2 || ''} ${addr.line1 || ''}`}
                  </td>
                  <td className="align-right">
                    {currencyFormatter.format(p.amount)}
                  </td>
                  <td>{p.merchantName}</td>
                  <td>
                    <time>{getTimeString(new Date(p.createdAt))}</time>
                  </td>
                  <td>
                    <span
                      className={`tag ${
                        p.status ? p.status.toLowerCase() : ''
                      }`}
                    >
                      {p.status}
                    </span>
                  </td>
                  <td className="align-right">
                    {!p.captures || !p.captures.length ? (
                      <span className="tag uncaptured">uncaptured</span>
                    ) : (
                      currencyFormatter.format(p.capturedAmount)
                    )}
                  </td>
                  <td className="align-right">
                    {currencyFormatter.format(p.refundedAmount)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {pageCount > 1 && (
        <Pagination pageCount={pageCount} page={page} gotoPage={gotoPage} />
      )}
    </>
  );
};

export default Payments;
