import React from 'react';

import Modal from '../../../../../Modal/Modal';

import './FormatErrorPopup.scss';
import { ErrorType } from '../../ReportSettingTabContent';

type FormatErrorPopupProps = {
  errorMsg: ErrorType[];
  handleClose: () => void;
};

const FormatErrorPopup: React.FC<FormatErrorPopupProps> = ({
  errorMsg,
  handleClose,
}) => {
  const showModal = Boolean(errorMsg.length);

  return showModal ? (
    <Modal isOpen={showModal} className="error-popup">
      <h5>The file you uploaded does not match the expected format.</h5>
      <p>Please review the fields and try again.</p>
      <ul>
        {errorMsg.map(err => (
          <li key={err?.value}>
            {`Incorrect data for ${err?.for || 'merchant_id:'}`}
            <b>{err?.value}</b>
            <span>{err?.message}</span>
          </li>
        ))}
      </ul>
      <button
        type="button"
        className="modal-close btn primary"
        onClick={handleClose}
      >
        Close
      </button>
    </Modal>
  ) : null;
};

export default FormatErrorPopup;
