import React from 'react';
import Tabs from '../Tabs/Tabs';
import ReportSettingTabContent from './TabsContent/ReportSetting/ReportSettingTabContent';

const MerchantPage = () => {
  const tabsData = [
    {
      key: 'application',
      icon: 'application',
      title: 'Application',
      disabled: true,
      content: () => <h3>Application</h3>,
    },
    {
      key: 'merchant',
      icon: 'application',
      title: 'Merchant',
      disabled: true,
      content: () => <h3>Merchant</h3>,
    },
    {
      key: 'report_setting',
      icon: 'application',
      title: 'Report setting',
      content: ReportSettingTabContent,
    },
  ];
  return (
    <Tabs tabs={tabsData} basePath="merchant" defaultTab="report_setting" />
  );
};

export default MerchantPage;
