// @flow
import React, { useState, lazy, Suspense } from 'react';
import { useQuery } from '@apollo/react-hooks';
import cx from 'classnames';
import { Link, Router, RouteComponentProps } from '@reach/router';

import LoadingPartial from '../Loading/LoadingPartial';
import NavLink from '../NavLink/NavLink';
import Card from '../Card/Card';
import Details from './Details/Details';
import PlusDetail from './PlusDetail/PlusDetail';
import Fraud from './Fraud/Fraud';
import Logs from './Logs/Logs';
import Messages from './Messages/Messages';
import Payments from './Payments/Payments';
import HoukatsuDetail from './Houkatsu/HoukatsuDetail';
import Van from './VAN/Van';
import FraudPayments from './FraudPayments/FraudPayments';
import LimitAndUsage from './LimitAndUsage/LimitAndUsage';

import gqlQuery from './gql-query';
import { getTimeString, onError } from '../../utils';

import styles from './ConsumerPage.module.scss';
import {
  PermissionChecker,
  withPermissionChecker,
} from '../PermissionChecker/PermissionChecker';
import { FRAUD_PERMISSION } from '../../utils/hooks/use-fraud-permission';

const ClosingAccount = lazy(() => import('./ClosingAccount/ClosingAccount'));
const EditingEmail = lazy(() => import('./EditingEmail/EditingEmail'));
const EditingPhone = lazy(() => import('./EditingPhone/EditingPhone'));

type ConsumerPageProps = {
  consumerId: string;
  hasHeader: boolean;
};

const RouterPage = (
  props: { pageComponent: JSX.Element } & RouteComponentProps
) => props.pageComponent;

const ConsumerPage = ({ consumerId, hasHeader }: ConsumerPageProps) => {
  const { loading, data } = useQuery(gqlQuery, {
    onError,
    variables: { consumerId },
  });

  const [closingAccount, setClosingAccount] = useState(false);
  const [editingEmail, setEditingEmail] = useState(false);
  const [editingPhone, setEditingPhone] = useState(false);

  const [updatedEmail, setUpdatedEmail] = useState('');
  const [updatedPhone, setUpdatedPhone] = useState('');
  const [updatedStatus, setUpdatedStatus] = useState();

  if (loading) {
    return <LoadingPartial />;
  }

  if (!data || !data.consumer) {
    return <div className="na-box" />;
  }

  const {
    consumer: {
      dateOfBirth,
      email,
      kanaName,
      kanjiName,
      phone,
      createdAt,
      status,
      closedAt,
      closedBy,
    },
    plusstatus: { appleStatus, ekycStatus, houkatsuStatus },
    plusConsumerData,
    activeFraudFlagsByConsumerId,
    assessmentExclusionsByConsumerId: { assessmentExclusions },
  } = data;

  const liquidId =
    plusConsumerData?.eKycConsumerData?.metadata?.liquidApplicationId || '';

  const latestStatus = updatedStatus || status;
  const isActionsDisabled = latestStatus.toLowerCase() === 'closed';
  const searchParams = hasHeader ? '' : '?header=0';
  const hasFraudFlag = activeFraudFlagsByConsumerId?.length > 0;
  const hasAssessment = assessmentExclusions?.length > 0;
  const FraudPaymentsWithPermission = withPermissionChecker(FraudPayments);

  return (
    <>
      <Card>
        <h3>顧客情報</h3>
        <div className={styles['id-flag-group']}>
          <h4 className={styles.id}>
            顧客ID<span>{consumerId}</span>
          </h4>
          {liquidId && (
            <h4 className={`${styles.id}  ${styles['liquid-id']}`}>
              リキッドID<span>{liquidId}</span>
            </h4>
          )}
          {hasFraudFlag && (
            <h4 className={styles.id}>
              <span className={styles.fraud}>Fraudフラグ</span>
            </h4>
          )}
          {hasAssessment && (
            <h4 className={styles.id}>
              <span className={styles.assessment}>Assessment除外フラグ</span>
            </h4>
          )}
        </div>

        <section className={styles['action-section']}>
          <nav className={styles.options}>
            <button
              className={cx(
                styles.option,
                styles.red,
                isActionsDisabled ? styles.disabled : ''
              )}
              onClick={() => {
                if (!isActionsDisabled) {
                  setClosingAccount(true);
                }
              }}
            >
              アカウントを解約
            </button>
            <button
              className={cx(styles.option)}
              onClick={() => setEditingEmail(true)}
            >
              メールアドレスを変更
            </button>
            <button
              className={cx(styles.option)}
              onClick={() => setEditingPhone(true)}
            >
              携帯電話番号を変更
            </button>
          </nav>
          {/* $FlowFixMe */}
          <Link
            className={cx('btn', styles.account)}
            to={`/accounts/${consumerId}${searchParams}`}
          >
            顧客アカウント
          </Link>
        </section>
        <section className={styles['info-section']}>
          <div>
            <div className={styles.label}>
              {kanaName || 'カナ名がありません'}
            </div>
            <div className={cx(styles.value, styles.name)}>
              <span className={styles.kanji}>{kanjiName}</span>
              <div className="tags">
                <span
                  className={`tag ${
                    latestStatus ? latestStatus.toLowerCase() : ''
                  }`}
                >
                  {latestStatus}
                </span>
                {ekycStatus === 'approved' && (
                  <span className={`tag plus-ekyc`}>PLUS EKYC</span>
                )}
                {houkatsuStatus === 'approved' && (
                  <span className={`tag plus-houkatsu`}>PLUS包括</span>
                )}
                {houkatsuStatus === 'approved_for_nlp' && (
                  <span className={`tag plus-houkatsu`}>NLP</span>
                )}
                <span className={`tag apple`}>{appleStatus}</span>
              </div>
            </div>
          </div>
          <div>
            <div className={styles.label}>生年月日</div>
            <div className={cx(styles.value, styles.dob)}>
              {dateOfBirth || 'Empty'}
            </div>
          </div>
          <div>
            <div className={styles.label}>連絡先</div>
            <div className={cx(styles.value, styles.email)}>
              {updatedEmail || email}
            </div>
            <div className={cx(styles.value, styles.phone)}>
              {updatedPhone || phone}
            </div>
          </div>
          <div>
            <div className={styles.label}>登録日</div>
            <div className={styles.value}>
              {getTimeString(new Date(createdAt))}
            </div>
          </div>
          {closedAt && (
            <div>
              <div className={styles.label}>アカウントの削除</div>
              <div className={styles.value}>
                <div>{getTimeString(new Date(closedAt))}</div>
                {closedBy && <div>By {closedBy}</div>}
              </div>
            </div>
          )}
        </section>
      </Card>
      <Card>
        <nav className="tabs">
          <NavLink
            title="顧客詳細"
            to={`/consumers/${consumerId}/details${searchParams}`}
          >
            顧客詳細
          </NavLink>
          <NavLink
            title="Limit & Usage"
            to={`/consumers/${consumerId}/limit_and_usage`}
          >
            Limit & Usage
          </NavLink>
          <NavLink
            title="ペイメント"
            to={`/consumers/${consumerId}/payments${searchParams}`}
          >
            ペイメント
          </NavLink>
          {(houkatsuStatus === 'approved' ||
            houkatsuStatus === 'approved_for_nlp') && (
            <NavLink title="包括契約" to={`/consumers/${consumerId}/houkatsu`}>
              包括契約
            </NavLink>
          )}
          {ekycStatus === 'approved' && (
            <NavLink
              title="PLUS情報"
              to={`/consumers/${consumerId}/plus${searchParams}`}
            >
              PLUS情報
            </NavLink>
          )}
          <NavLink title="Fraud" to={`/consumers/${consumerId}/fraud`}>
            Fraud
          </NavLink>
          <PermissionChecker
            permissions={[FRAUD_PERMISSION.PAYMENT_FRAUD_FLAGS_READ]}
          >
            <NavLink
              title="FraudPayments"
              to={`/consumers/${consumerId}/fraudpayments`}
            >
              Fraudペイメント
            </NavLink>
          </PermissionChecker>
          <NavLink
            title="ログ"
            to={`/consumers/${consumerId}/logs${searchParams}`}
          >
            ログ
          </NavLink>
          <NavLink
            title="メッセージ ログ"
            to={`/consumers/${consumerId}/messages${searchParams}`}
          >
            メッセージ ログ
          </NavLink>
          <NavLink title="VAN" to={`/consumers/${consumerId}/van`}>
            VAN
          </NavLink>
        </nav>
        <Router primary={false}>
          {/** $FlowFixMe */}
          <RouterPage
            path="details"
            pageComponent={
              <Details
                consumer={data.consumer}
                consumerId={consumerId}
                hasHeader={hasHeader}
              />
            }
          />
          <RouterPage path="houkatsu" pageComponent={<HoukatsuDetail />} />
          <RouterPage
            path="plus"
            pageComponent={
              <PlusDetail
                plusData={ekycStatus === 'approved' ? plusConsumerData : null}
              />
            }
          />
          <RouterPage path="fraud" pageComponent={<Fraud />} />
          <RouterPage
            path="logs"
            pageComponent={<Logs consumerId={consumerId} />}
          />
          <RouterPage
            path="messages"
            pageComponent={<Messages consumerId={consumerId} />}
          />
          <RouterPage
            path="payments"
            pageComponent={
              <Payments payments={data.payments} hasHeader={hasHeader} />
            }
          />
          <RouterPage
            path="van"
            pageComponent={<Van consumerId={consumerId} />}
          />
          <RouterPage
            path="fraudpayments"
            pageComponent={
              <FraudPaymentsWithPermission
                payments={data.payments}
                hasHeader={hasHeader}
                permissions={[FRAUD_PERMISSION.PAYMENT_FRAUD_FLAGS_READ]}
              />
            }
          />
          <RouterPage
            path="limit_and_usage"
            pageComponent={
              <LimitAndUsage
                consumerId={consumerId}
                isNlpApproved={houkatsuStatus === 'approved_for_nlp'}
              />
            }
          />
        </Router>
      </Card>
      <Suspense fallback={<LoadingPartial />}>
        <ClosingAccount
          isOpen={closingAccount}
          setIsOpen={setClosingAccount}
          consumerId={consumerId}
          setUpdatedStatus={setUpdatedStatus}
          kanaName={kanaName}
          kanjiName={kanjiName}
          dateOfBirth={dateOfBirth}
          email={email}
        />
      </Suspense>
      <Suspense fallback={<LoadingPartial />}>
        <EditingEmail
          isOpen={editingEmail}
          setIsOpen={setEditingEmail}
          setUpdatedEmail={setUpdatedEmail}
          consumerId={consumerId}
        />
      </Suspense>
      <Suspense fallback={<LoadingPartial />}>
        <EditingPhone
          isOpen={editingPhone}
          setIsOpen={setEditingPhone}
          setUpdatedPhone={setUpdatedPhone}
          consumerId={consumerId}
        />
      </Suspense>
    </>
  );
};

export default ConsumerPage;
