import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useUnmountableCustomModalContext } from './UnmountableCustomModal';
import { useParams } from '@reach/router';

import modalStyles from '../../Modal/Modal.module.scss';
import { ErrorMessage, LoadingMessage } from './ModalMessages';
import {
  updateGpsTokenStatus,
  gpsQuery,
  updatePhysicalCardStatus,
} from './gql-query';

type UpdateGPSTokenStatusModalProps = {
  text: string;
  token: string;
  updateGpsToken: boolean;
  statusToUpdate: string;
};

const useUpdateGPSTokenStatus = (
  consumerId: string,
  token: string,
  updateGpsToken: boolean,
  statusToUpdate: string,
  onCompleted: (data: any) => void
) => {
  /* eslint react-hooks/rules-of-hooks: warn
    --------
    Dispaly warning for the hook to avoid the code level changs,
    Will address the warning in the future. */
  if (updateGpsToken) {
    return useMutation(updateGpsTokenStatus, {
      variables: { consumerId, token, status: statusToUpdate },
      onCompleted,
      update: (cache: any, res) => {
        if (res && res.data && res.data.updateGpsTokenStatus !== 'Success')
          return;

        const variables = { consumerId, token, status: statusToUpdate };

        const { gpsTokens = [] } = cache.readQuery({
          query: gpsQuery,
          variables,
        });

        const data = {
          gpsTokens: gpsTokens.map((gps: any) => {
            if (gps.token === token) {
              return {
                ...gps,
                status: statusToUpdate,
              };
            }
            return gps;
          }),
        };

        cache.writeQuery({
          query: gpsQuery,
          variables,
          data,
        });
      },
    });
  }

  const variables = {
    consumerId,
    token,
    physicalCardStatus: statusToUpdate,
  };
  return useMutation(updatePhysicalCardStatus, {
    variables,
    onCompleted,
    refetchQueries: () => [
      {
        query: gpsQuery,
        variables: { consumerId },
      },
    ],
  });
};

export const UpdateGPSTokenStatusModal = ({
  text,
  token,
  updateGpsToken,
  statusToUpdate,
}: UpdateGPSTokenStatusModalProps) => {
  const { onCancel, onConfirm } = useUnmountableCustomModalContext();
  const { consumerId } = useParams();
  const onCompleted = onConfirm;

  const [mutate, { loading, error }] = useUpdateGPSTokenStatus(
    consumerId,
    token,
    updateGpsToken,
    statusToUpdate,
    onCompleted
  );

  return (
    <>
      <h2>
        {updateGpsToken ? 'Update GPS Token Status' : 'Update IdemiaID Status'}
      </h2>
      <LoadingMessage loading={loading}></LoadingMessage>
      <ErrorMessage error={error}></ErrorMessage>
      {!loading && !error && (
        <div>
          Are you sure you want to <em>{text}?</em>
        </div>
      )}

      <form onSubmit={e => e.preventDefault()}>
        <div className={modalStyles.actions}>
          {!error && !loading && (
            <button
              type="button"
              className={'btn red'}
              onClick={() => mutate()}
            >
              OK
            </button>
          )}

          {!loading && (
            <button type="button" className={'btn'} onClick={onCancel}>
              キャンセル
            </button>
          )}
        </div>
      </form>
    </>
  );
};
