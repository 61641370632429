import React from 'react';

import Modal from '../../../../../Modal/Modal';

type SubmittedPopupProps = {
  description: string | null;
  handleClose: () => void;
};

const SubmittedPopup: React.FC<SubmittedPopupProps> = ({
  description,
  handleClose,
}) => {
  const showModal = Boolean(description);

  return showModal ? (
    <Modal isOpen={showModal}>
      <h5 style={{ marginBottom: 15 }}>{description}</h5>
      <button
        type="button"
        className="modal-close btn primary"
        onClick={handleClose}
      >
        Close
      </button>
    </Modal>
  ) : null;
};

export default SubmittedPopup;
