import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const getNlpMigrationStatus = gql`
  query nlpMigrationStatus($consumerId: ID!) {
    nlpMigrationStatus(consumerId: $consumerId) {
      status
    }
  }
`;

export enum nlpMigrationStatuses {
  approved = 'approved',
  canceled = 'canceled',
  never_started = 'never_started',
  awaiting_cic_results = 'awaiting_cic_results',
  pac_data_required = 'pac_data_required',
  awaiting_pac_assessment = 'awaiting_pac_assessment',
  rejected = 'rejected',
}

type NLPMigrationStatusResponse = {
  nlpMigrationStatus: {
    status: nlpMigrationStatuses;
    __typename: 'NlpMigrationStatus';
  };
};

export const useMigrationStatusQuery = (consumerId: string) => {
  const { data } = useQuery<NLPMigrationStatusResponse, { consumerId: string }>(
    getNlpMigrationStatus,
    { variables: { consumerId } }
  );

  return { data };
};

type MonthlyPowerChange = {
  cycle: string;
  nextMonthDue: number;
  unconfirmedPaymentsAndUnconfirmedFutureExposures: number;
  predictiveMonthlyPower: number;
  remainingBalanceOfAvailableAmount: number;
  __typename: 'MonthlyPowerChange';
};

type SyntheticMonthlyPowerEntry = {
  name: string;
  amount: number;
  __typename: 'SyntheticMonthlyPowerEntry';
};

type SyntheticMonthlyPower = {
  plus: SyntheticMonthlyPowerEntry[];
  __typename: 'SyntheticMonthlyPower';
};

type NlpReport = {
  __typename: 'NlpReport';
  consumerId: string;
  computationCycle: string;
  nmdLimit: number;
  nextMonthDue: number;
  multiplier: number;
  treLimit: number;
  tre: number;
  availableAmount: number;
  currentMonthlyPower: number;
  unconfirmedPayments: number;
  closedBillsAmount: number;
  changeInMonthlyPower: MonthlyPowerChange[];
  syntheticMonthlyPower: SyntheticMonthlyPower;
};

type NlpReportResponse = {
  nlpReport: NlpReport;
};

const getNlpReport = gql`
  query nlpReport($consumerId: ID!) {
    nlpReport(consumerId: $consumerId) {
      consumerId
      computationCycle
      nmdLimit
      nextMonthDue
      multiplier
      treLimit
      tre
      availableAmount
      currentMonthlyPower
      unconfirmedPayments
      closedBillsAmount
      changeInMonthlyPower {
        cycle
        nextMonthDue
        unconfirmedPaymentsAndUnconfirmedFutureExposures
        predictiveMonthlyPower
        remainingBalanceOfAvailableAmount
      }
      syntheticMonthlyPower {
        plus {
          name
          amount
        }
      }
    }
  }
`;

export const useNlpReportQuery = ({
  consumerId,
  skip,
}: {
  consumerId: string;
  skip: boolean;
}) => {
  const { data } = useQuery<NlpReportResponse, { consumerId: string }>(
    getNlpReport,
    { variables: { consumerId }, skip }
  );

  return { data };
};
