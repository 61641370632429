import gql from 'graphql-tag';

export const getFlaggingReasons = gql`
  query flaggingReasons {
    flaggingReasons {
      id
      reason
    }
  }
`;

export const getFraudFlags = gql`
  query activeFraudFlagsByConsumerId($consumerId: ID!) {
    activeFraudFlagsByConsumerId(consumerId: $consumerId) {
      id
      flaggedFor
      flaggedBy
      flaggedAt
      consumerId
    }
  }
`;

export const getFraudEventLogs = gql`
  query fraudManagementEventLogs($consumerId: ID!) {
    fraudManagementEventLogs(consumerId: $consumerId) {
      eventLogs {
        id
        createdBy
        createdAt
        message
      }
      error {
        kind
        description
      }
    }
  }
`;

export const createFraudFlag = gql`
  mutation createFraudFlagV2($consumerId: ID!, $flaggedFor: String!) {
    createFraudFlagV2(consumerId: $consumerId, flaggedFor: $flaggedFor) {
      id
      error
    }
  }
`;

export const deleteFraudFlag = gql`
  mutation unflagConsumerFromFraudFlag(
    $consumerId: ID!
    $flagId: String!
    $message: String!
  ) {
    unflagConsumerFromFraudFlag(
      consumerId: $consumerId
      flagId: $flagId
      message: $message
    )
  }
`;

export const createFraudEventLog = gql`
  mutation createFraudManagementEventLog($consumerId: ID!, $message: String!) {
    createFraudManagementEventLog(consumerId: $consumerId, message: $message) {
      id
      error {
        kind
        description
      }
    }
  }
`;

// ===========================================

export const getAssessments = gql`
  query assessmentExclusionsByConsumerId($consumerId: ID!) {
    assessmentExclusionsByConsumerId(consumerId: $consumerId) {
      assessmentExclusions {
        id
        consumerId
        assessmentKind
        excludedBy
        excludedAt
      }
      error {
        kind
        description
      }
    }
  }
`;

export const getAssessmentKinds = gql`
  query excludableAssessmentsByConsumerId($consumerId: ID!) {
    excludableAssessmentsByConsumerId(consumerId: $consumerId) {
      kinds
      error {
        kind
        description
      }
    }
  }
`;

export const createAssessment = gql`
  mutation createAssessmentExclusion(
    $consumerId: ID!
    $assessmentKind: String!
  ) {
    createAssessmentExclusion(
      consumerId: $consumerId
      assessmentKind: $assessmentKind
    ) {
      id
      error {
        kind
        description
      }
    }
  }
`;

export const deleteAssessment = gql`
  mutation deactivateAssessmentExclusion($consumerId: ID!, $exclusionId: ID!) {
    deactivateAssessmentExclusion(
      consumerId: $consumerId
      exclusionId: $exclusionId
    ) {
      id
      error {
        kind
        description
      }
    }
  }
`;
