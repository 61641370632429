// @flow
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';

import introspectionResult from './introspectionResult.json';

import { API_HOST } from './constants';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: introspectionResult,
});

export const clientCreator = () => {
  const httpLink = createHttpLink({
    uri: `${API_HOST}/graphql`,
    credentials: 'include',
  });

  // $FlowFixMe
  return new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache({
      fragmentMatcher,
    }),
  });
};

export default clientCreator;
