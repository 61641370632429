import { useParams } from '@reach/router';
import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from 'react-apollo';
import { getDateString } from '../../../utils';
import { eKycConsumerDataFragment, plusstatusFragment } from '../gql-query';

const plusApplicationDatesQuery = gql`
  ${plusstatusFragment}
  ${eKycConsumerDataFragment}
  query PlusApplicationDates($consumerId: ID!) {
    plusConsumerData(consumerId: $consumerId) {
      eKycConsumerData {
        ...eKycConsumerData
      }
      approvedAt
    }
    plusstatus(consumerId: $consumerId) {
      ...plusstatus
    }
  }
`;

const PlusApplicationDateRows = () => {
  const { consumerId } = useParams();
  const { data, loading } = useQuery(plusApplicationDatesQuery, {
    variables: { consumerId },
  });

  if (loading) return null;

  const plusData = data && data.plusConsumerData;

  const eKYCData = plusData && plusData.eKycConsumerData;

  const approvedAt = plusData && plusData.approvedAt;

  const lastUpdatedDate = approvedAt || (eKYCData && eKYCData.updatedAt) || '';

  const formattedLastUpdatedDate =
    lastUpdatedDate !== ''
      ? getDateString(new Date(lastUpdatedDate))
      : lastUpdatedDate;

  return (
    <>
      <tr>
        <th>審査確定日</th>
        <td>{formattedLastUpdatedDate}</td>
      </tr>
    </>
  );
};

export default PlusApplicationDateRows;
