import gql from 'graphql-tag';

export const cancelContract = gql`
  mutation cancelContract($installmentPlanId: ID!) {
    cancelContract(installmentPlanId: $installmentPlanId, test: false)
  }
`;

export const threePayContract = gql`
  fragment threePayContract on ThreePayContract {
    dateConverted
    id: installmentPlanId
    paymentId
    merchantName
    status
    totalCaptureAmount
    outstandingAmount
    refundAmount
  }
`;

export const sixPayContract = gql`
  fragment sixPayContract on SixPayContract {
    dateConverted
    id: installmentPlanId
    paymentId
    merchantName
    status
    totalCaptureAmount
    outstandingAmount
    refundAmount
  }
`;

export const twelvePayContract = gql`
  fragment twelvePayContract on TwelvePayContract {
    dateConverted
    id: installmentPlanId
    paymentId
    merchantName
    status
    totalCaptureAmount
    outstandingAmount
    refundAmount
  }
`;

export const appleContract = gql`
  fragment appleContract on AppleContract {
    dateConverted
    id: installmentPlanId
    paymentId
    merchantName
    status
    totalCaptureAmount
    outstandingAmount
    refundAmount
  }
`;

export const contractsByConsumerId = gql`
  ${threePayContract}
  ${sixPayContract}
  ${twelvePayContract}
  ${appleContract}

  query contractsByConsumerId($consumerId: ID!) {
    threePayContracts(consumerId: $consumerId, test: false) {
      ...threePayContract
    }
    sixPayContracts(consumerId: $consumerId, test: false) {
      ...sixPayContract
    }
    twelvePayContracts(consumerId: $consumerId, test: false) {
      ...twelvePayContract
    }
    appleContracts(consumerId: $consumerId, test: false) {
      ...appleContract
    }
  }
`;
