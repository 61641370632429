import React, {
  FC,
  createRef,
  KeyboardEvent,
  ClipboardEvent,
  useEffect,
  ChangeEvent,
  useState,
  UIEvent,
} from 'react';

import styles from './SearchInput.module.scss';

const EnterKeyValue = 'Enter';

const Mark: FC<{ item: FilterItem; index: number }> = ({ item, index }) => {
  return (
    <>
      {index !== 0 ? ',' : ''}
      {!item.matched ? <mark>{item.value}</mark> : `${item.value}`}
    </>
  );
};

export interface FilterItem {
  value: string;
  matched?: boolean;
}

interface SearchInputProps {
  initialValue?: FilterItem[];
  placeholder?: string;
  onFilterChange?: (values: FilterItem[]) => void;
  onPaste?: (event: ClipboardEvent<HTMLInputElement>) => void;
}

export const SearchInput: FC<SearchInputProps> = ({
  initialValue = [],
  onFilterChange,
  placeholder,
  onPaste,
}) => {
  const markRef = createRef<HTMLDivElement>();
  const [filters, setFilters] = useState<FilterItem[]>([]);

  useEffect(() => {
    setFilters(initialValue);
  }, [initialValue]);

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === EnterKeyValue) {
      const value = event.currentTarget.value.trim();
      if (value) {
        const filters = value.split(',').map(v => ({ value: v }));
        onFilterChange && onFilterChange(filters);
      } else if (!value && initialValue.length > 0) {
        onFilterChange && onFilterChange([]);
      }
    }
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();
    if (value) {
      const newFilters = value
        .split(',')
        .map(v => ({ value: v, matched: true }));
      setFilters(newFilters);
    } else if (!value && filters.length > 0) {
      setFilters([]);
      onFilterChange && onFilterChange([]);
    }
  };

  const onInputScroll = (event: UIEvent<HTMLInputElement>) => {
    markRef.current?.scrollTo({
      left: event.currentTarget.scrollLeft,
    });
  };

  const displayError = filters.some(v => !v.matched);

  return (
    <div>
      <div className={styles['search-input']}>
        <div className={styles['search-input__icon']}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0.833 1.54 18.328 16.913"
            width="18.328"
            height="16.913"
          >
            <g transform="matrix(0.91, 0, 0, 0.91, 10, 9.999999)">
              <path
                vectorEffect="non-scaling-stroke"
                transform=" translate(-12.01, -12.01)"
                d="M 15.639 14.43 L 21.704 19.52 C 21.98635721202805 19.746941148225275 22.123903517506065 20.108839745971302 22.06355702243941 20.4660318238163 C 22.00321052737276 20.823223901661304 21.754408490144662 21.119850143710686 21.41316863598511 21.241436499340033 C 21.071928781825555 21.363022854969383 20.691615745595232 21.290555098867358 20.419 21.052 L 14.303 15.921 C 11.29915800115344 18.48218544889212 6.824185596660982 18.276543222273727 4.067900267234313 15.450657725577415 C 1.3116149378076436 12.624772228881104 1.217609869523299 8.14606373273242 3.8528930756123483 5.207012851689994 C 6.4881762817013975 2.2679619706475673 10.950578726868496 1.8747302785063535 14.059242957942608 4.30761834746712 C 17.16790718901672 6.740506416427886 17.858605800030567 11.166633637270177 15.639000000000003 14.430999999999997 z M 9.434999999999999 15.715 C 12.472566124069363 15.715 14.934999999999999 13.252566124069364 14.934999999999999 10.215 C 14.934999999999999 7.177433875930635 12.472566124069363 4.715 9.434999999999999 4.715 C 6.397433875930634 4.715 3.9349999999999987 7.177433875930635 3.9349999999999987 10.215 C 3.9349999999999987 13.252566124069364 6.397433875930634 15.715 9.434999999999999 15.715 z"
                strokeLinecap="round"
              />
            </g>
          </svg>
        </div>

        <div className={styles['search-input__container']} onPaste={onPaste}>
          <div className={styles['search-input__mark']} ref={markRef}>
            {filters.map((item, index) => (
              <Mark key={index} index={index} item={item} />
            ))}
          </div>
          <input
            type="search"
            onPaste={onPaste}
            spellCheck={false}
            placeholder={placeholder}
            className={styles['search-input__input']}
            value={filters.map(item => item.value).join(',')}
            onChange={onInputChange}
            onKeyDown={onKeyDown}
            onScroll={onInputScroll}
          />
        </div>
      </div>
      {displayError && (
        <span className={styles['search-error']}>
          入力エラーがあります。もう一度入力してください。
        </span>
      )}
    </div>
  );
};
