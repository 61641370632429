import React from 'react';
import cx from 'classnames';
import ReactPaginate from 'react-paginate';

import styles from './Pagination.module.scss';

type PaginationProps = {
  page: number;
  gotoPage: Function;
  pageCount: number;
  isTop?: boolean;
};

const Pagination = ({ page, gotoPage, pageCount, isTop }: PaginationProps) => {
  const handlePageClick = (event: any) => {
    gotoPage(event.selected);
  };

  return (
    <div className={cx(styles.paginationContainer, isTop ? styles.top : '')}>
      <ReactPaginate
        forcePage={page}
        previousLabel={'Prev'}
        nextLabel={'Next'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={4}
        onPageChange={handlePageClick}
        containerClassName={styles.pagination}
        // subContainerClassName={cx(styles.pagination, styles.page)}
        activeClassName={'active'}
      />
    </div>
  );
};

Pagination.defaultProps = {
  isTop: false,
};

export default Pagination;
