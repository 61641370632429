import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import {
  UnmountableCustomModalProvider,
  useUnmountableCustomModalContext,
  UnmountableModalWrapper,
} from '../PlusDetail/UnmountableCustomModal';

import LoadingPartial from '../../Loading/LoadingPartial';
import AssignVANModal from './AssignVANModal';

import { getVAN } from './gql-query';

import styles from './Van.module.scss';

type VanProps = { consumerId: string };

const Van = ({ consumerId }: VanProps) => {
  const { loading, data } = useQuery(getVAN, {
    variables: { consumerId },
  });

  const AssignVANButton = () => {
    const { onOpen } = useUnmountableCustomModalContext();

    return (
      <>
        <button
          className={`btn secondary ${styles['btn-van']}`}
          onClick={onOpen}
        >
          VANを割り当て
        </button>
        <UnmountableModalWrapper>
          <AssignVANModal />
        </UnmountableModalWrapper>
      </>
    );
  };

  if (loading) {
    return <LoadingPartial />;
  }

  if (!data || !data.van) {
    return (
      <div className={`na-box ${styles['na-box--van']}`}>
        <UnmountableCustomModalProvider>
          <AssignVANButton />
        </UnmountableCustomModalProvider>
      </div>
    );
  }

  return (
    <div className="table">
      <table>
        <thead>
          <tr>
            <th>受取人名</th>
            <th>番号</th>
            <th>口座種別</th>
            <th>支店名</th>
            <th>支店番号</th>
            <th>金融機関</th>
            <th>VAN Key</th>
          </tr>
        </thead>
        <tbody>
          {data ? (
            <tr>
              <td>{data.van.accountName}</td>
              <td>{data.van.accountNumber}</td>
              <td>{data.van.accountType}</td>
              <td>{data.van.bankName}</td>
              <td>{data.van.branchCode}</td>
              <td>{data.van.branchName}</td>
              <td>{data.van.vanKey}</td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </div>
  );
};

export default Van;
