// @flow
import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import cx from 'classnames';

import styles from './LoginPage.module.scss';

const RESEND_WAIT = 30 * 1000;

type PinInputProps = {
  index: number;
  last: boolean;
  pins: Array<string>;
  pinNodes: Array<any>;
  setPin: Function;
  setActivationFailed: Function;
  setCursor: Function;
};

type ActivationFormProps = {
  activationFailed: string;
  pin: string;
  setActivationFailed: Function;
  setPin: Function;
  resendPin: Function;
  onActive: any;
};

const normalizePinChar = (input: string) => {
  return input ? input.replace(/[^0-9]/g, '').slice(0, 1) : '';
};

const genPinString = ({
  index,
  curr,
  prev,
  pins,
}: {
  index: number;
  curr: string;
  prev: string;
  pins: string[];
}) => {
  let str = '';

  for (let i = 0; i < pins.length; i += 1) {
    if (i === index - 1) {
      str += prev || ' ';
    } else if (i === index) {
      str += curr || ' ';
    } else {
      str += pins[i] || ' ';
    }
  }

  return str;
};

export const PinInput = ({
  index,
  last,
  pins,
  pinNodes,
  setPin,
  setActivationFailed,
  setCursor,
}: PinInputProps) => {
  const onKeyDown =
    index === 0
      ? () => null
      : (event: any) => {
          if (event.key !== 'Backspace') {
            return;
          }

          event.preventDefault();

          const cursorStart = event.currentTarget.selectionStart;
          const cursorEnd = event.currentTarget.selectionEnd;

          let prev = pins[index - 1];
          let curr = pins[index];

          if (!curr) {
            prev = ' ';
          }

          if (cursorStart || cursorEnd) {
            curr = ' ';
          }

          setPin(genPinString({ index, curr, prev, pins }));
          setActivationFailed('');

          if (cursorStart === 0 && cursorStart === cursorEnd) {
            setCursor(index - 1);
          }
        };

  const onChange = (event: any) => {
    const v = normalizePinChar(event.currentTarget.value);
    const curr = v || ' ';
    const prev = pins[index - 1];

    setPin(genPinString({ index, curr, prev, pins }));
    setActivationFailed('');
  };

  // Use input event to move cursor. Avoids user input the same pin code which don't trigger change.
  const onInput = (event: any) => {
    const v = normalizePinChar(event.currentTarget.value);

    if (v) {
      setCursor(last ? 99 : index + 1);
    }
  };

  return (
    <input
      className={styles.pin}
      type="text"
      name={`pin_${index}`}
      id={`pin_${index}`}
      value={pins[index]}
      maxLength={1}
      autoComplete="off"
      onKeyDown={onKeyDown}
      onChange={onChange}
      onInput={onInput}
      onFocus={event => {
        if (event.currentTarget.value) {
          event.currentTarget.select();
        }
      }}
      ref={pinNodes[index]}
    />
  );
};

PinInput.defaultProps = {
  last: false,
};

const ActivationForm = ({
  activationFailed,
  pin,
  setActivationFailed,
  setPin,
  resendPin,
  onActive,
}: ActivationFormProps) => {
  const [showResend, setShowResend] = useState(false);

  const [pinValid, setPinValid] = useState(false);
  const [cursor, setCursor] = useState(0);

  const submitNode = useRef(null);

  const pin0Node = useRef(null);
  const pin1Node = useRef(null);
  const pin2Node = useRef(null);
  const pin3Node = useRef(null);

  const pinNodes: any[] = [pin0Node, pin1Node, pin2Node, pin3Node];

  const pin0 = (pin[0] || '').trim();
  const pin1 = (pin[1] || '').trim();
  const pin2 = (pin[2] || '').trim();
  const pin3 = (pin[3] || '').trim();

  const pins = [pin0, pin1, pin2, pin3];

  useEffect(() => {
    const resendBoxWaitTimer = setTimeout(() => {
      setShowResend(true);
    }, RESEND_WAIT);

    return () => {
      clearTimeout(resendBoxWaitTimer);
    };
  });

  useEffect(() => {
    setPinValid(pin.replace(/\s/g, '').length === 4);
  }, [pin]);

  useLayoutEffect(() => {
    for (let i = 0; i < pins.length; i += 1) {
      if (!pins[i]) {
        if (pinNodes[i].current) {
          pinNodes[i].current.focus();
        }

        break;
      }
    }
  }, [activationFailed]);

  useLayoutEffect(() => {
    const node = cursor === 99 ? submitNode : pinNodes[cursor];

    if (node.current && node.current.focus) {
      node.current.focus();
    }
  }, [cursor]);

  const defaultPinInputProp = {
    pins,
    pinNodes,
    setPin,
    setActivationFailed,
    setCursor,
  };

  return (
    <form onSubmit={onActive}>
      <p>
        登録したメールアドレスに届いた
        <br />
        4桁の認証コードを入力してください
      </p>
      <fieldset
        className={cx(
          styles['pin-field'],
          activationFailed ? styles.invalid : ''
        )}
      >
        <PinInput index={0} {...defaultPinInputProp} />
        <PinInput index={1} {...defaultPinInputProp} />
        <PinInput index={2} {...defaultPinInputProp} />
        <PinInput index={3} last {...defaultPinInputProp} />
      </fieldset>
      {activationFailed && (
        <p className={cx(styles.error, styles['pin-error'])}>
          正しい認証コードを入力してください
        </p>
      )}
      {showResend && (
        <div className={styles.resend}>
          認証コードが届きませんか？
          <br />
          <button
            onClick={() => {
              resendPin();
              setPin('');
              setCursor(0);
            }}
          >
            再送する
          </button>
        </div>
      )}
      <div className={styles.actions}>
        <button
          type="submit"
          className={cx('btn primary full', pinValid ? 'active' : 'disabled')}
          ref={submitNode}
        >
          認証する
        </button>
      </div>
    </form>
  );
};

export default ActivationForm;
