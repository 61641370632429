// @flow
import React from 'react';
import { useParams } from '@reach/router';
import { useMutation } from '@apollo/react-hooks';
import { useUnmountableCustomModalContext } from '../PlusDetail/UnmountableCustomModal';

import modalStyles from '../../Modal/Modal.module.scss';

import { getVAN, assignVAN } from './gql-query';
import { ErrorMessage, LoadingMessage } from '../PlusDetail/ModalMessages';

const useAssignVAN = (consumerId: string, onCompleted: (data: any) => void) => {
  return useMutation(assignVAN, {
    variables: { consumerId },
    onCompleted,
    update: (cache, res) => {
      cache.writeQuery({
        query: getVAN,
        variables: { consumerId },
        data: {
          van: res.data.assignVan,
        },
      });
    },
  });
};

const AssignVANModal = () => {
  const { onCancel, onConfirm } = useUnmountableCustomModalContext();
  const { consumerId } = useParams();

  const onCompleted = onConfirm;
  const [mutate, { loading, error }] = useAssignVAN(consumerId, onCompleted);

  const disabled = loading;

  const disableClassName = (classNameString: string) =>
    disabled ? 'btn disabled' : classNameString;

  return (
    <>
      <h2>このお客様にVANを割り当てますか？</h2>
      <LoadingMessage loading={loading}></LoadingMessage>
      <ErrorMessage error={error}></ErrorMessage>
      <form onSubmit={e => e.preventDefault()}>
        <div className={modalStyles.actions}>
          <button
            disabled={disabled}
            onClick={() => mutate()}
            className={disableClassName('btn red')}
          >
            割当
          </button>
          <button
            disabled={disabled}
            onClick={() => onCancel()}
            className={disableClassName('btn blue')}
          >
            キャンセル
          </button>
        </div>
      </form>
    </>
  );
};

export default AssignVANModal;
