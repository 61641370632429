// @flow
import React, { useState, useEffect } from 'react';
import { Link } from '@reach/router';

import Pagination from '../../Pagination/Pagination';

import { getTimeString, pagerize } from '../../../utils';

type ConsumersProps = {
  consumers: Array<any>;
  updateUIPairs: Function;
};

type ConsumerTableProps = { consumers: Array<any> };

const Consumers = ({ consumers, updateUIPairs }: ConsumersProps) => {
  useEffect(() => {
    updateUIPairs({ searchType: 'consumers' });
  }, []);

  if (!consumers || !consumers.length) {
    return <div className="na-box" />;
  }

  return <ConsumerTable consumers={consumers} />;
};

const PER_PAGE = 100;

const ConsumerTable = ({ consumers }: ConsumerTableProps) => {
  const [page, setPage] = useState(0);

  if (!consumers || !consumers.length) {
    return <div className="na-box" />;
  }

  const pageCount = consumers ? Math.ceil(consumers.length / PER_PAGE) : 0;
  const pagedConsumers = pagerize({
    entries: consumers,
    page,
    perPage: PER_PAGE,
  });

  const gotoPage = (p: number) => {
    setPage(p);
  };

  return (
    <div className="table">
      <table>
        <thead>
          <tr>
            <th>名前</th>
            <th>作成日</th>
            <th>生年月日</th>
            <th>メールアドレス</th>
            <th>電話番号</th>
            <th>ステータス</th>
          </tr>
        </thead>
        <tbody>
          {pagedConsumers.map((c, i) => {
            return (
              <tr key={i}>
                <td className="no-wrap">
                  {/* $FlowFixMe */}
                  <Link to={`/consumers/${c.id}/details`}>
                    {!c.kanjiName && !c.kanaName ? (
                      'N/A'
                    ) : (
                      <>
                        {c.kanjiName}
                        <br />
                        {c.kanaName}
                      </>
                    )}
                  </Link>
                </td>
                <td>
                  <time>{getTimeString(new Date(c.createdAt))}</time>
                </td>
                <td>
                  <time>{c.dateOfBirth || 'Empty'}</time>
                </td>
                <td>{c.email}</td>
                <td>{c.phone}</td>
                <td>
                  <span
                    className={`tag ${c.status ? c.status.toLowerCase() : ''}`}
                  >
                    {c.status}
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {pageCount > 1 && (
        <Pagination pageCount={pageCount} page={page} gotoPage={gotoPage} />
      )}
    </div>
  );
};

export default Consumers;
